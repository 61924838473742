import { graphql, useStaticQuery } from 'gatsby';

const useTestimonialsData = () => {
  const { allStrapiTestimonials: { nodes: data }, allStrapiTestimonialsBackground } = useStaticQuery(graphql`
    {
      allStrapiTestimonials {
        nodes {
          strapiId
          name_bs
          company_bs
          quote_bs
          name_en
          company_en
          quote_en
          name_de
          company_de
          quote_de
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allStrapiTestimonialsBackground {
        nodes {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return {
    data,
    testimonialsBackground: allStrapiTestimonialsBackground.nodes[0].image.childImageSharp.fluid,
  };
};

export default useTestimonialsData;
