import { graphql, useStaticQuery } from 'gatsby';

const useAreasData = () => {
  const { allStrapiAreas: { nodes: data }, allStrapiIndustriesBackground: { nodes: industriesDetails }, } = useStaticQuery(graphql`
    {
      allStrapiAreas {
        nodes {
          id
          strapiId
          industry_bs
          industry_en
          industry_de
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allStrapiIndustriesBackground {
        nodes {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title_bs
          title_en
          title_de
        }
      }
    }
  `);
  return {
    data,
    industriesDetails,
  };
};

export default useAreasData;
