import React, { useContext, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import BackgroundImage from 'gatsby-background-image';

import useStatisticsData from 'queries/StatisticsQueries';
import { LanguageContext } from 'components/Layout';

import styles from './statistics.module.scss';

const Statistics = () => {
  const { language } = useContext(LanguageContext);
  const [statisticsSeen, setStatisticsSeen] = useState(false);
  const content = useStatisticsData();
  return (
    <BackgroundImage
      fluid={content.img[0].image.childImageSharp.fluid}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={styles.statsContainer}>
        {
        content.data.map((item) => (
          <div className={styles.statsColumn} key={item.strapiId}>
            <span className={styles.number}>
              <CountUp end={item.number} redraw start={statisticsSeen ? 0 : null}>
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => isVisible && setStatisticsSeen(true)}
                    delayedCall
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              +
            </span>
            <h2>{item[`text_${language}`]}</h2>
          </div>
        ))
      }
      </div>
    </BackgroundImage>
  );
};

export default Statistics;
