import React, { useState, useRef, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';

import PropTypes from 'prop-types';

import useWorkProcessData from 'queries/WorkProcessQueries';
import { LanguageContext } from 'components/Layout';
import SliderArrow from 'components/SliderArrow';
import useWindowWidth from 'utilities/windowWidth';
import ZoomableImage from './WorkProcessZoomableImage';
import ContactPerson from './ContactPerson';
import styles from './workprocess.module.scss';

const WorkProcessSection = ({ sectionId }) => {
  const {
    allStrapiWorkProcesses: { nodes: content },
  } = useWorkProcessData();
  const { language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const sliderRef = useRef(null);

  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const setCenterPadding = (wW) => (wW > 1440 ? '10%' : '5%');
  const setGalleryCenterPadding = (wW) => (wW > 1430 ? '20%' : '5%');
  const setSlidesToShow = (wW) => (wW > 800 ? 3 : 2);
  const setGallerySlidesToShow = (wW) => (wW > 800 ? 2 : 2);

  const desktopSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: setSlidesToShow(windowWidth),
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: setCenterPadding(windowWidth),
  };

  const gallerySettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: setGallerySlidesToShow(windowWidth),
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: setGalleryCenterPadding(windowWidth),
  }

  return (
    <div className={styles.workProcessSection}>
      {content.map((section) => {
        if (section.strapiId === sectionId) {
          return (
            <Fade right key={section.strapiId}>
              <ContactPerson sectionId={sectionId} />
              <hr />
              <h4>{section[`title_${language}`]}</h4>
              <p className={styles.sectionText}>{section[`text_${language}`]}</p>
              <Slider
                {...section.work_process_images.length > 3 ? {...desktopSettings} : {...gallerySettings}}
                className={styles.slider}
                ref={(n) => (sliderRef.current = n)}
              >
                {section.work_process_images.map((img) => (
                  <ZoomableImage key={img.image.id} img={img} />
                )
                )}
              </Slider>
              <div className={styles.arrows}>
                <SliderArrow onClick={goBack} prevArrow />
                <SliderArrow onClick={goNext} prevArrow={false} />
              </div>
            </Fade>
          );
        }
        return null;
      })}
    </div>
  );
};

WorkProcessSection.propTypes = {
  sectionId: PropTypes.number.isRequired,
};

export default WorkProcessSection;
