import React from 'react';

import Layout from 'components/Layout';
import MainSlider from 'components/Home/MainSlider/MainSlider';

import WorkProcesses from 'components/Home/WorkProcess';
import AreasOfExpertise from 'components/Home/AreasOfExpertise/AreasOfExpertise';

import Statistics from 'components/Home/Statistics/Statistics';
import News from 'components/Home/News/News';
import Testimonials from 'components/Home/Testimonials/Testimonials';
import VideoSection from 'components/Home/VideoSection/index';
import ScrollTopArrow from 'utilities/scrollToTop';

import homePic from 'images/home.jpg';
import './index.scss';
import Logos from 'components/Home/Logos';

const IndexPage = () => (
  <Layout title="GAT - Technology" description="MEHANIČKA OBRADA. ODSJEK MEHANIČKE OBRADE - Skladište materijala, Skladište alata, CNC tokarenje, CNC glodanje, Montaža" image={homePic}>
    <ScrollTopArrow />
    <MainSlider />
    <VideoSection />
    <WorkProcesses />
    <AreasOfExpertise />
    <Statistics />
    <News />
    <Testimonials />
    <Logos />
  </Layout>
);

export default IndexPage;
