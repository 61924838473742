import { graphql, useStaticQuery } from 'gatsby';

const useMainSliderData = () => {
  const { allStrapiMainslider } = useStaticQuery(graphql`
    query   {
        allStrapiMainslider {
          nodes {
            title_bs
            subtitle_bs
            button_bs
            title_en
            subtitle_en
            button_en
            title_de
            subtitle_de
            button_de
            strapiId
            images {
              image1 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image2 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image3 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image4 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image5 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image6 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image7 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image8 {
                id
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
    }
  `);

  const sliderData = allStrapiMainslider.nodes[0];

  return {
    textStrings: {
      title_bs: sliderData.title_bs,
      subtitle_bs: sliderData.subtitle_bs,
      button_bs: sliderData.button_bs,
      title_en: sliderData.title_en,
      subtitle_en: sliderData.subtitle_en,
      button_en: sliderData.button_en,
      title_de: sliderData.title_de,
      subtitle_de:sliderData.subtitle_de,
      button_de: sliderData.button_de,
    },
    strapiId: sliderData.strapiId,
    images: sliderData.images,
  };
};

export default useMainSliderData;
