import { graphql, useStaticQuery } from 'gatsby';

const useWorkProcessData = () => {
  const { strapiMiddleTitle, allStrapiWorkProcesses, allStrapiOtherFields } = useStaticQuery(graphql`
      {
        strapiMiddleTitle {
          title_bs
          title_en
          title_de
          backgroundImg {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        allStrapiWorkProcesses(sort: {order: ASC, fields: strapiId}) {
          nodes {
            contact_person {
              id
              name
              position_bs
              position_en
              position_de
              number
              email
              avatar {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            strapiId
            text_bs
            title_bs
            text_en
            title_en
            text_de
            title_de
            work_process_images {
              image {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000, jpegQuality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                  original {
                    width
                    height
                  }
                }
                id
              }
            }
          }
        }
        allStrapiOtherFields {
          nodes {
            contact_person_bos
            contact_person_de
            contact_person_en
          }
        }
      }
`);
  return { 
    strapiMiddleTitle, 
    allStrapiWorkProcesses, 
    contactPersonTitle: {
      contact_person_bs: allStrapiOtherFields.nodes[0].contact_person_bos,
      contact_person_en: allStrapiOtherFields.nodes[0].contact_person_en,
      contact_person_de: allStrapiOtherFields.nodes[0].contact_person_de,
    }};
};

export default useWorkProcessData;
