import React, { useState, useEffect } from 'react';

import WorkProcessHeader from './WorkProcessHeader';
import WorkProcessTitles from './WorkProcessTitles';
import WorkProcessMobile from './WorkProcessMobile';

const WorkProcesses = () => {
  const [mobileProcessesShown, setMobileProcessesShown] = useState(false);

  useEffect(() => {
    if (mobileProcessesShown) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [mobileProcessesShown]);

  return (
    <>
      {mobileProcessesShown ? (
        <WorkProcessMobile
          mobileProcessesShown={mobileProcessesShown}
          setMobileProcessesShown={setMobileProcessesShown}
        />
      ) : (
        <>
          <WorkProcessHeader
            setMobileProcessesShown={setMobileProcessesShown}
          />
          <WorkProcessTitles />
        </>
      )}
    </>
  );
};

export default WorkProcesses;
