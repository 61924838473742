import { graphql, useStaticQuery } from 'gatsby';

const useNewsData = () => {
  const { allStrapiNews: { nodes: data }, allStrapiOtherFields } = useStaticQuery(graphql`
  {
    allStrapiNews {
      nodes {
        strapiId
        title_bs
        short_text_bs
        text_bs
        url_text_bs
        title_en
        short_text_en
        text_en
        url_text_en
        title_de
        short_text_de
        text_de
        url_text_de
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiOtherFields {
      nodes {
        news_title_bos
        news_title_de
        news_title_en
      }
    }
  }
  `);

  return {
    data,
    newsTitle: {
      news_title_bs: allStrapiOtherFields.nodes[0].news_title_bos,
      news_title_en: allStrapiOtherFields.nodes[0].news_title_en,
      news_title_de: allStrapiOtherFields.nodes[0].news_title_de,
    }
  };
};

export default useNewsData;
