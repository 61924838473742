import { graphql, useStaticQuery } from 'gatsby';

const useStatisticsData = () => {
  const { allStrapiStatistics: { nodes: data }, allStrapiStatisticsBackground: { nodes: img } } = useStaticQuery(graphql`
    {
      allStrapiStatistics {
        nodes {
          strapiId
          text_bs
          text_en
          text_de
          number
        }
      }
      allStrapiStatisticsBackground {
        nodes {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return {
    data, img,
  };
};

export default useStatisticsData;
