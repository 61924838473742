import React, { useRef, useState, useContext } from 'react';
import Slider from 'react-slick';
import Image from 'gatsby-image';
import useNewsData from 'queries/NewsQueries';
import useWindowWidth from 'utilities/windowWidth';
import { Link } from 'gatsby-plugin-intl';
import { LanguageContext } from 'components/Layout';
import SliderArrow from 'components/SliderArrow';
import createSlug from '../../../utilities/slugHelper';

import styles from './news.module.scss';

const News = () => {
  const content = useNewsData();
  const sliderRef = useRef(null);
  const { language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  let showDots = false;
  if (windowWidth > 700) {
    showDots = true;
  }

  const setCenterPadding = (wW) => (wW > 1200 ? '23%' : '12%');

  const desktopSettings = {
    centerMode: true,
    centerPadding: setCenterPadding(windowWidth),
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: showDots,
  };

  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className={styles.newsContainer}>
      <h2 className={styles.title}>{content.newsTitle[`news_title_${language}`]}</h2>
      {
        windowWidth < 768 ? (
          <Slider {...mobileSettings} className={styles.slider} ref={(n) => (sliderRef.current = n)}>
            {
            content.data.map((item) => (
              <div key={item.strapiId} className={styles.newsSlide}>
                <Image fluid={item.image.childImageSharp.fluid} className={styles.newsImage} />
                <div className={styles.newsInfo}>
                  <h3 className={styles.newsTitle}>{item[`title_${language}`]}</h3>
                  <p>{item[`short_text_${language}`]}</p>
                  <Link to={`/news/${createSlug(item.title_en)}-${item.strapiId}`}>{item[`url_text_${language}`]}</Link>
                </div>
              </div>
            ))
        }
          </Slider>
        ) : (
          <Slider {...desktopSettings} className={styles.slider}>
            {
            content.data.map((item) => (
              <div key={item.strapiId}>
                <div className={styles.newsSlide}>
                  <Image fluid={item.image.childImageSharp.fluid} className={styles.newsImage} />
                  <div className={styles.newsInfo}>
                    <h3 className={styles.newsTitle}>{item[`title_${language}`]}</h3>
                    <p>{item[`short_text_${language}`]}</p>
                    <Link to={`/news/${createSlug(item.title_en)}-${item.strapiId}`}>{item[`url_text_${language}`]}</Link>
                  </div>
                </div>

              </div>
            ))
        }
          </Slider>
        )
      }
      <div className={styles.arrows}>
        <SliderArrow onClick={goBack} prevArrow />
        <SliderArrow onClick={goNext} prevArrow={false} />
      </div>
    </div>
  );
};

export default News;
