import React, { useContext, useState } from 'react';
import YouTube from 'react-youtube';

import { LanguageContext } from 'components/Layout';
import useWindowWidth from 'utilities/windowWidth';
import background from 'images/headerBg.png';
import styles from './video.module.scss';

const VideoSection = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });
  const { language } = useContext(LanguageContext);
  const text = {
    bs: 'Upoznajte nas',
    en: 'Get to know us',
    de: 'Lerne uns kennen',
  };
  const videoId = {
    bs: 'VNTHPT-qiyg',
    en: 'mxf1rcFNIOQ',
    de: 'TBkA5mhrdyI',
  };
  const opts = {
    height: windowWidth < 770 ? '60%' : '80%',
    width: windowWidth < 480 ? '90%' : '80%',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };
  return (
    <div className={styles.videoContainer}>
      <img src={background} alt="Blue background" />
      <p className={styles.text}>{text[`${language}`]}</p>
      <YouTube videoId={videoId[`${language}`]} opts={opts} className={styles.video} />
    </div>
  );
};

export default VideoSection;
