import React, {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';

import Image from 'gatsby-image';
import Zoom from 'react-medium-image-zoom';
import BackgroundImage from 'gatsby-background-image';
import classNames from 'classnames';
import Slider from 'react-slick';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import useWorkProcessData from 'queries/WorkProcessQueries';
import useFooterData from 'queries/FooterQueries';
import { LanguageContext } from 'components/Layout';

import styles from '../workprocess.module.scss';
import ContactPerson from '../ContactPerson';

const WorkProcessMobile = (props) => {
  const {
    strapiMiddleTitle: headerContent,
    allStrapiWorkProcesses: { nodes: content },
  } = useWorkProcessData();
  const data = useFooterData();

  const { language } = useContext(LanguageContext);

  const [activeSlide, setActiveSlide] = useState(0);
  const [numberOfSlides, setNumberOfSlides] = useState(content.length);
  const [contentScrolled, setContentScrolled] = useState(false);
  const sliderRef = useRef(null);
  const sectionRef = useRef();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const mobileSettings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    arrows: false,
  };

  const imageSlider = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '20%',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      if (activeSlide + 1 >= numberOfSlides) {
        setActiveSlide(0);
      } else {
        setActiveSlide((cur) => cur + 1);
      }
      sectionRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      if (activeSlide === 0) {
        setActiveSlide(numberOfSlides - 1);
      } else {
        setActiveSlide((cur) => cur - 1);
      }
      sectionRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (sectionRef.current) {
      const { scrollTop } = sectionRef.current;
      if (scrollTop > 0) {
        setContentScrolled(true);
      } else {
        setContentScrolled(false);
      }
    }
  };

  return (
    <div className={styles.mobileWorkProcessesWrapper} onScroll={() => handleScroll()} ref={sectionRef}>
      <BackgroundImage
        fluid={headerContent.backgroundImg.childImageSharp.fluid}
        id="workProcesses"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className={classNames(
            styles.newPageHeader,
          )}
        >
          <div onClick={() => props.setMobileProcessesShown(false)}>
            <Image
              className={styles.logo}
              fluid={data.logo.childImageSharp.fluid}
            />
          </div>
          <h2 className={styles.middleTitle}>
            {headerContent[`title_${language}`]}
          </h2>
        </div>
      </BackgroundImage>
      <div className={styles.workProcesses}>
        <div
          className={classNames(
            styles.mobileTitleSlide,
            styles.fixedHeader,
          )}
        >
          <button
            className={classNames(styles.prevMobileArrow, styles.mobileArrow)}
            type="button"
            onClick={goBack}
          >
            <BsChevronLeft />
          </button>
          <div className={styles.mobileTitle}>
            {content[activeSlide][`title_${language}`]}
          </div>
          <div className={styles.activeSectionArrow} />
          <button
            className={classNames(styles.nextMobileArrow, styles.mobileArrow)}
            type="button"
            onClick={goNext}
          >
            <BsChevronRight />
          </button>
        </div>
        <Slider
          {...mobileSettings}
          draggable={false}
          className={styles.mobileTitles}
          ref={(n) => (sliderRef.current = n)}
        >
          {content.map((section) => (
            <div key={section.strapiId} className={styles.workProcessSlide}>
              <div className={styles.workProcessSection}>
                <p className={styles.sectionText}>
                  {section[`text_${language}`]}
                </p>
                <Slider
                  {...imageSlider}
                  className={styles.slider}
                  ref={(n) => (sliderRef.current = n)}
                >
                  {section.work_process_images.map((img) => (
                    <Zoom
                      overlayBgColorEnd="rgba(0,0,0,0.7)"
                      overlayBgColorStart="transparent"
                      zoomMargin={20}
                      key={img.image.id}
                      wrapStyle={{ borderRadius: '0px' }}
                    >
                      <Image
                        fluid={img.image.childImageSharp.fluid}
                        className={styles.processImage}
                      />
                    </Zoom>
                  ))}
                </Slider>
              </div>
            </div>
          ))}
        </Slider>
        <ContactPerson
          bottomReached={contentScrolled}
          sectionId={content[activeSlide].strapiId}
        />
      </div>
    </div>
  );
};

export default WorkProcessMobile;
