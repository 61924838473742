import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "./style.scss";

const SliderArrow = (props) => {
  const { prevArrow, onClick } = props;

  return (
    <div onClick={onClick} role="presentation" className="custom-slider-arrows">
      {prevArrow ? (
        <>
          <BsChevronLeft className="arrow-top slider-arrow" />
          <BsChevronLeft className="arrow-middle slider-arrow" />
          <BsChevronLeft className="arrow-bottom slider-arrow" />
        </>
      ) : (
        <>
          <BsChevronRight className="arrow-top slider-arrow" />
          <BsChevronRight className="arrow-middle slider-arrow" />
          <BsChevronRight className="arrow-bottom slider-arrow" />
        </>
      )}
    </div>
  );
};

export default SliderArrow;
