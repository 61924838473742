/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import BackgroundImage from 'gatsby-background-image';
import { BsChevronRight } from 'react-icons/bs';
import useWorkProcessData from 'queries/WorkProcessQueries';
import useWindowWidth from 'utilities/windowWidth';
import { LanguageContext } from 'components/Layout';

import styles from './workprocess.module.scss';

const WorkProcessHeader = (props) => {
  const { strapiMiddleTitle : content } = useWorkProcessData();

  const { language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <BackgroundImage
      fluid={content.backgroundImg.childImageSharp.fluid}
      id="workProcesses"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {
        windowWidth > 600 ? (
          <div className={styles.workProcessHeader}>
            <h2 className={styles.middleTitle}>{content[`title_${language}`]}</h2>
            <span>{'>'}</span>
          </div>
        ) : (
          <div
            className={styles.mobileWorkProcessHeader}
            role="presentation"
            onClick={() => props.setMobileProcessesShown(true)}
          >
            <h2 className={styles.middleTitle}>{content[`title_${language}`]}</h2>
            <div className={styles.arrowGroup}>
              <BsChevronRight className={classNames(styles.arrowTop, styles.arrow)} />
              <BsChevronRight className={classNames(styles.arrowMiddle, styles.arrow)} />
              <BsChevronRight className={classNames(styles.arrowBottom, styles.arrow)} />
            </div>
          </div>
        )
      }
    </BackgroundImage>
  );
};

export default WorkProcessHeader;
