import React, { useContext } from 'react';
import Image from 'gatsby-image';
import Slider from 'react-slick';
import { Link } from 'gatsby-plugin-intl';

import useMainSliderData from 'queries/MainSliderQueries';
import { LanguageContext } from 'components/Layout';

import styles from './mainslider.module.scss';

const MainSlider = () => {
  const {
    textStrings,
    images,
  } = useMainSliderData();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { language } = useContext(LanguageContext);
  return (
    <div className={styles.mainHeader}>
      <div className={styles.sliderContainer}>
        <div className={styles.slideContainer}>
          <div className={styles.centerSection}>
            <h1 className={styles.mainTitle}>{textStrings[`title_${language}`]}</h1>
            <h3 className={styles.subtitle}>{textStrings[`subtitle_${language}`]}</h3>
            <Link to="/contact">
              <button type="button" className={styles.contactButton}>
                {textStrings[`button_${language}`]}
              </button>
            </Link>
          </div>
        </div>
        <div>
          <Slider {...settings} className={styles.slider}>
            <Image
              key={images.image1.id}
              fluid={images.image1.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image2.id}
              fluid={images.image2.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image3.id}
              fluid={images.image3.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image4.id}
              fluid={images.image4.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image5.id}
              fluid={images.image5.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image6.id}
              fluid={images.image6.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image7.id}
              fluid={images.image7.childImageSharp.fluid}
              className={styles.slider1}
            />
            <Image
              key={images.image8.id}
              fluid={images.image8.childImageSharp.fluid}
              className={styles.slider1}
            />
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MainSlider;
