import React, { useState, useContext } from 'react';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Slider from 'react-slick';

import { LanguageContext } from 'components/Layout';
import useTestimonialsData from 'queries/TestimonialsQueries';
import useWindowWidth from 'utilities/windowWidth';

import styles from './testimonials.module.scss';

const Testimonials = () => {
  const content = useTestimonialsData();
  const { language } = useContext(LanguageContext);

  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <BackgroundImage fluid={content.testimonialsBackground}>
      {windowWidth < 670 ? (
        <Slider {...mobileSettings} className={styles.testimonialsContainer}>
          {content.data.map((item) => (
            <div key={item.strapiId} className={styles.testimonialSlider}>
              <Image
                fluid={item.avatar.childImageSharp.fluid}
                className={styles.avatar}
              />
              <h3>{item.name_bs}</h3>
              {item.company_bs.includes('www')
                ? <a href={`https://${item.company_bs}`} target="_blank" rel="noreferrer">{item.company_bs}</a>
                : <span>{item.company_bs}</span>}
              <p>
                &quot;
                {item[`quote_${language}`]}
                &quot;
              </p>
            </div>
          ))}
        </Slider>
      ) : (
        <div className={styles.testimonialsContainer}>
          {content.data.map((item) => (
            <div key={item.strapiId} className={styles.testimonial}>
              <Image
                fluid={item.avatar.childImageSharp.fluid}
                className={styles.avatar}
              />
              <h3>{item.name_bs}</h3>
              {item.company_bs.includes('www')
                ? <a href={`https://${item.company_bs}`} target="_blank" rel="noreferrer">{item.company_bs}</a>
                : <span>{item.company_bs}</span>}
              <p>
                &quot;
                {item[`quote_${language}`]}
                &quot;
              </p>
            </div>
          ))}
        </div>
      )}
    </BackgroundImage>
  );
};

export default Testimonials;
