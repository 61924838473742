import React, { useRef, useState, useContext } from 'react';
import Image from 'gatsby-image';
import Slider from 'react-slick';
import BackgroundImage from 'gatsby-background-image';
import useAreasData from 'queries/AreasOfExpertiseQueries';
import useWindowWidth from 'utilities/windowWidth';
import SliderArrow from 'components/SliderArrow';
import styles from './areasofexpertise.module.scss';
import { LanguageContext } from '../../Layout';

const AreasOfExpertise = () => {
  const content = useAreasData();
  const sliderRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });
  const { language } = useContext(LanguageContext);
  let numberOfSlides = 3;

  if (windowWidth < 820) {
    numberOfSlides = 1;
  }

  const setCenterPadding = (wW) => (wW > 1000 ? '7%' : wW < 820 ? '30%' : '20%');

  const desktopSettings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: setCenterPadding(windowWidth),
    slidesToShow: numberOfSlides,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <BackgroundImage
      fluid={content.industriesDetails[0].image.childImageSharp.fluid}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={styles.industryContainer} id="industries">
        <h2 className={styles.title}>{content.industriesDetails[0][`title_${language}`]}</h2>
        {
          windowWidth < 670 ? (
            <Slider
              {...mobileSettings}
              className={styles.slider}
              ref={(n) => (sliderRef.current = n)}
            >
              {
            content.data.map((item) => (
              <div key={item.strapiId} className={styles.card}>
                <Image
                  key={item.strapiId}
                  fluid={item.image.childImageSharp.fluid}
                  className={styles.industryImage}
                />
                <div className={styles.industry}>
                  <h3>{item[`industry_${language}`]}</h3>
                </div>
              </div>
            ))
          }
            </Slider>
          ) : (
            <Slider
              {...desktopSettings}
              className={styles.slider}
              ref={(n) => (sliderRef.current = n)}
            >
              {
            content.data.map((item) => (
              <div key={item.strapiId} className={styles.card}>
                <Image
                  key={item.strapiId}
                  fluid={item.image.childImageSharp.fluid}
                  className={styles.industryImage}
                />
                <div className={styles.industry}>
                  <h3>{item[`industry_${language}`]}</h3>
                </div>
              </div>
            ))
          }
            </Slider>
          )
        }
        <div className={styles.arrows}>
          <SliderArrow onClick={goBack} prevArrow />
          <SliderArrow onClick={goNext} prevArrow={false} />
        </div>
      </div>
    </BackgroundImage>
  );
};

export default AreasOfExpertise;
