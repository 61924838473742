import React, { useCallback, useState } from 'react'
import Image from 'gatsby-image';
import classNames from 'classnames';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import styles from './workprocess.module.scss';

const ZoomableImage = (props) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom 
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      overlayBgColorEnd="rgba(0,0,0,0.7)"
      overlayBgColorStart="transparent"
      zoomMargin={50}
    >
      <Image
        fluid={props.img.image.childImageSharp.fluid}
        className={classNames(styles.processImage, isZoomed ? styles.zoomedImage : '')}
        />
    </ControlledZoom>
  )
}

export default ZoomableImage;
