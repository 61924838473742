import React, { useState, useContext } from 'react';
import Image from 'gatsby-image';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useWorkProcessData from 'queries/WorkProcessQueries';
import useWindowWidth from 'utilities/windowWidth';
import { LanguageContext } from 'components/Layout';

import styles from './workprocess.module.scss';

const ContactPerson = ({ sectionId, bottomReached }) => {
  const { language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const { allStrapiWorkProcesses : { nodes: content }, contactPersonTitle } = useWorkProcessData();

  const contactUsText = {
    bs: 'Pišite nam',
    en: 'Contact us',
    de: 'Kontakt',
  };

  return (
    <div
      className={classNames(
        styles.contactContainer,
        bottomReached ? styles.hiddenContact : '',
      )}
    >
      {
        content.map((section) => {
          if (section.strapiId === sectionId) {
            const {
              id, name, number, email, avatar,
            } = section.contact_person;
            return (
              <div key={id} className={styles.contactSection}>
                <div className={styles.mobileContactSection}>
                  <Image fluid={avatar?.childImageSharp?.fluid} className={styles.contactAvatar} />
                  {
                    windowWidth < 600 ? (
                      <a href={`mailto:${email}`}><button type="button" className={styles.contactButton}>{contactUsText[language]}</button></a>
                    ) : null
                  }
                </div>
                <div className={styles.contactInfo}>
                  <h3>{contactPersonTitle[`contact_person_${language}`]}</h3>
                  <h4>
                    {name}
                    {' / '}
                    {section.contact_person[`position_${language}`]}
                  </h4>
                  <span>
                    {email}
                  </span>
                  <span>
                    {number === '/' ? '' : number}
                  </span>
                </div>
                {
                  windowWidth > 600 ? (
                    <a href={`mailto:${email}`}><button type="button" className={styles.contactButton}>{contactUsText[language]}</button></a>
                  ) : null
                }
              </div>
            );
          }
          return null;
        })
      }
    </div>
  );
};

ContactPerson.propTypes = {
  sectionId: PropTypes.number.isRequired,
  bottomReached: PropTypes.bool,
};

ContactPerson.defaultProps = {
  bottomReached: false,
};

export default ContactPerson;
