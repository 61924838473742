import React, { useState, useContext } from 'react';
import classNames from 'classnames';

import useWindowWidth from 'utilities/windowWidth';
import useWorkProcessData from 'queries/WorkProcessQueries';
import { LanguageContext } from 'components/Layout';

import styles from './workprocess.module.scss';
import WorkProcessSection from './WorkProcessSection';

const WorkProcessTitles = () => {
  const [activeSectionId, setActiveSectionId] = useState(1);

  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const { language } = useContext(LanguageContext);
  const { allStrapiWorkProcesses : { nodes: content } } = useWorkProcessData();

  return (
    <div className={styles.workProcesses}>
      <div className={styles.titles}>
        {
          content.map((section) => (
            <div key={section.strapiId} className={styles.titleSidebar} role="presentation">
              <button
                type="button"
                className={classNames(activeSectionId === section.strapiId ? styles.activeSection : '')}
                onClick={() => setActiveSectionId(section.strapiId)}
              >
                <span>{section[`title_${language}`]}</span>
                {activeSectionId === section.strapiId ? (
                  <div className={styles.activeSectionArrow} />
                ) : null}
              </button>
            </div>
          ))
        }
      </div>
      <div className={styles.workProcessWrapper}>
        <WorkProcessSection sectionId={activeSectionId} />
      </div>
    </div>
  );
};

export default WorkProcessTitles;
